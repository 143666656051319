.latestWorkSection {
    position: relative;
    padding: 20px 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
}

.latestProjectsWrapper {
    margin-bottom: 40px;
    font-size: 32px;
    z-index: 2;
}

.badgeText {
    font-family: 'Roboto Slab', serif;
    color: #000;
}

.heroImagesWrapper {
    display: flex;
    gap: 10px;
    will-change: transform;
}

.heroImageWrapper {
    width: 400px; /* Default width for larger screens */
    height: 400px; /* Default height for larger screens */
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.imageFill {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Responsive Adjustments for Mobile Devices */
@media (max-width: 768px) {
    .heroImageWrapper {
        border-radius: 6px;
        width: 180px; /* Adjusted width for tablets and small screens */
        height: 180px; /* Adjusted height for tablets and small screens */
    }
}

@media (max-width: 480px) {
    .heroImageWrapper {
        border-radius: 4px;
        width: 100px; /* Further adjust width for mobile phones */
        height: 100px; /* Further adjust height for mobile phones */
    }
}
