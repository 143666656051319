.faq-section {
    background-color: #EFE7F7;
    padding: 20px;
    text-align: left;
}

.faq-title {
    font-family: 'Roboto Slab', serif;
    font-size: 48px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 60px;
    text-align: center;
}

.faq-list {
    max-width: 800px;
    margin: 0 auto;
}

.faq-item {
    margin-bottom: 20px;
    border-bottom: 1px solid #000000;
    overflow: hidden;
}

.faq-question {
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    color: #000000;
    display: flex;
    align-items: center; /* Align content vertically */
    justify-content: space-between;
    cursor: pointer;
    padding: 20px 0;
    position: relative;
}

.faq-icon {
    font-size: 24px;
    transition: transform 0.3s ease-in-out;
    margin-left: 10px; /* Add space between the question text and the icon */
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    margin-bottom: 20px;
}

.faq-answer.active {
    max-height: 200px;
    opacity: 1;
}

.faq-answer p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #000000;
    padding: 10px 0;
    margin: 0;
}

.faq-question.active .faq-icon {
    transform: rotate(90deg); /* Smooth rotation when expanded */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .faq-title {
        font-size: 36px;
        margin-bottom: 40px;
    }

    .faq-question {
        font-size: 20px;
        padding: 15px 0;
    }

    .faq-icon {
        font-size: 20px;
    }

    .faq-answer p {
        font-size: 16px;
        padding: 8px 0;
    }

    .faq-list {
        padding: 0 15px;
    }
}
