body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2 {
  color: #000000;
}

section {
  padding: 50px 20px;
  text-align: center;
}

#hero {
  padding: 100px 20px;
}

.main-container {
  align-items: center;
  max-width: 1600px; /* Set a max-width for larger screens */
  width: 100%; /* Ensure it takes full width on smaller screens */
  margin: 0 auto; /* Center the container */
  padding: 0 15px; /* Add padding to ensure content doesn't touch the edges */
  box-sizing: border-box; /* Ensures padding is included in width calculation */
}

/* Make sections more fluid */
@media (max-width: 1200px) {
  section {
    padding: 40px 15px;
  }

  #hero {
    padding: 80px 15px;
  }
}

@media (max-width: 768px) {
  section {
    padding: 30px 10px;
  }

  #hero {
    padding: 60px 10px;
  }

  .main-container {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  section {
    padding: 20px 5px;
  }

  #hero {
    padding: 50px 20px;
  }

  .main-container {
    padding: 0 0px;
  }
}
