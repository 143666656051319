html {
    scroll-behavior: smooth;
}

.benefits-section {
    background-color: #EFE7F7;
    padding: 80px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.benefits-content {
    text-align: center;
    max-width: 1200px;
    width: 100%;
}

.benefits-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 64px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0px;
}

.benefits-subheading {
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    color: #000000;
    margin-bottom: 60px;
}

.benefits-features {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.feature-item {
    flex: 1;
    max-width: 360px;
    text-align: center;
    margin-bottom: 20px; /* Add vertical space between items */
}

.feature-icon {
    width: 160px;
    height: 160px;
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin: 0 auto 20px auto; /* Center the entire icon block horizontally */
}

.feature-description {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    color: #000000;
    line-height: 1.5;
}

.cta-button {
    display: inline-block;
    width: 200px;
    padding: 15px 30px;
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    color: #000000;
    background: linear-gradient(to right, #FEDE64, #D3AEFF);
    border: 3px solid black;
    border-radius: 60px;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .benefits-features {
        flex-direction: column;
        align-items: center;
    }

    .feature-item {
        max-width: none;
        width: 100%;
        margin-bottom: 40px; /* Increase vertical spacing for stacked items */
    }

    .feature-icon {
        width: 100px; /* Smaller icon size for mobile */
        height: 100px;
    }

    .benefits-heading {
        font-size: 36px;
    }

    .benefits-subheading {
        font-size: 20px;
    }

    .cta-button {
        width: 160px;
        font-size: 18px;
        padding: 10px 15px;
    }
}

@media (max-width: 480px) {
    .cta-button {
        width: 160px;
        font-size: 18px;
        padding: 10px 15px;
    }
}