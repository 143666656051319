/* General styles for the hero section */
.hero {
    padding: 100px 20px;
    text-align: center;
    background-color: #EFE7F7;
}

.hero-content {
    max-width: 1200px;
    margin: 0 auto;
}

.moone-logo {
    width: 150px;
    height: auto;
    margin-bottom: 40px;
}

.hero-title {
    font-family: 'Roboto Slab', serif;
    font-size: 96px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
}

.hero-subtitle {
    font-family: 'Roboto Slab', sans-serif;
    font-size: 24px;
    color: #000000;
    margin-bottom: 40px;
}

.cta-button {
    display: inline-block;
    width: 200px;
    padding: 15px 30px;
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    color: #000000;
    background: linear-gradient(to right, #FEDE64, #D3AEFF);
    border: 3px solid black;
    border-radius: 60px;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 1024px) {
    .hero-title {
        font-size: 72px; /* Reduce title size for tablets */
    }

    .cta-button {
        font-size: 22px;
        padding: 15px 20px;
    }
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 48px; /* Further reduce title size for smaller devices */
    }

    .moone-logo {
        width: 120px; /* Reduce logo size */
        margin-bottom: 30px;
    }

    .hero-subtitle {
        font-size: 20px;
    }

    .cta-button {
        width: 180px;
        font-size: 20px;
        padding: 12px 20px;
    }
}

@media (max-width: 480px) {
    .hero-title {
        font-size: 36px; /* Title size for mobile devices */
    }

    .moone-logo {
        width: 100px; /* Further reduce logo size */
        margin-bottom: 20px;
    }

    .hero-subtitle {
        font-size: 18px;
    }

    .cta-button {
        width: 160px;
        font-size: 18px;
        padding: 10px 15px;
    }
}
