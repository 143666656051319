.services-section {
    background-color: #EFE7F7;
    padding: 80px 0; /* Increased padding for more space around the section */
    text-align: center;
}

.services-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 64px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 40px; /* Increased margin to add more space between heading and services */
}

.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.marquee {
    display: inline-block;
    animation: marquee 25s linear infinite; /* Smooth infinite loop */
}

.service-item {
    display: inline-block;
    margin: 0 20px; /* Increased spacing between service items */
    padding: 12px 24px; /* Slightly increased padding for more space within each service item */
    border-radius: 50px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #ffffff;
}

.orange {
    background-color: #FE7C00;
}

.purple {
    background-color: #9B51E0;
}

.blue {
    background-color: #2D9CDB;
}

.teal {
    background-color: #27AE60;
}

.pink {
    background-color: #EB5757;
}

@keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); } /* Move by 50% to loop seamlessly */
}

@media (max-width: 768px) {
    .services-heading {
        font-size: 32px;
    }

    .service-item {
        font-size: 16px;
        margin: 0 16px; /* Adjusted margin for better spacing on mobile */
        padding: 10px 20px; /* Adjusted padding for mobile */
    }
}
