/* General styles for the section */
.membership-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    background-color: #EFE7F7;
}

/* Card container */
.membership-card {
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    padding: 60px 40px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}


.membership-card.is-visible {
    opacity: 1;
    transform: translateY(0); /* Move to original position */
}


/* Title */
.membership-title {
    font-family: 'Roboto Slab', serif;
    font-size: 48px;
    font-weight: 400;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 0px;
}

/* Price */
.membership-price {
    font-family: 'Roboto Slab', serif;
    font-size: 64px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
}

/* Note */
.membership-note {
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 40px;
}

/* Divider line */
.membership-divider {
    width: calc(100% - 20px); /* Divider is 20px shorter than the card's width */
    border-top: 1px solid #000000;
    margin: 40px auto; /* Centers the divider */
}

/* Features title */
.membership-features-title {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    color: #000000;
    margin-bottom: 40px;
}

/* Features list */
.membership-features {
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    color: #000000;
    line-height: 1.8;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: start;
    gap: 20px 40px;
    margin-bottom: 40px;
}

.cta-button {
    display: inline-block;
    width: 200px;
    padding: 15px 30px;
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    color: #000000;
    background: linear-gradient(to right, #FEDE64, #D3AEFF);
    border: 3px solid black;
    border-radius: 60px;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

/* Keyframes for the fade-in-up animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .membership-card {
        padding: 40px 20px; /* Adjust padding for smaller screens */
    }

    .membership-divider {
        width: calc(100% - 20px); /* The width of the card minus 20px */
        margin: 20px auto; /* Adjust margin to keep it centered */
    }

    .membership-title, .membership-features-title {
        font-size: 24px; /* Adjust font size for smaller screens */
    }

    .membership-price {
        font-size: 48px; /* Adjust font size for smaller screens */
    }

    .membership-note {
        font-size: 18px; /* Adjust font size for smaller screens */
    }

    .membership-features {
        text-align: center;
        font-size: 18px;
        grid-template-columns: 1fr; /* Stack features vertically on small screens */
        gap: 10px; /* Reduce gap between items */
    }

    .cta-button {
        width: 180px;
        font-size: 20px;
        padding: 12px 20px;
    }
}

@media (max-width: 480px) {
    .cta-button {
        width: 160px;
        font-size: 18px;
        padding: 10px 15px;
    }
}