.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
    background-color: #EFE7F7;
    text-align: center;
    margin-bottom: 100px;
}

.contact-content {
    max-width: 800px;
    width: 100%;
}

.contact-heading {
    font-family: 'Roboto Slab', serif;
    font-size: 48px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
}

.contact-subheading {
    font-family: 'Roboto Slab', sans-serif;
    font-size: 20px;
    color: #000000;
    margin-bottom: 40px;
    line-height: 1.6;
}
