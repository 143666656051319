.footer {
    background: linear-gradient(to right, #FEDE64, #D3AEFF); /* Apply the gradient background */
    padding: 60px 0; /* Add some padding to the footer */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
}

.footer-content {
    text-align: center; /* Center align text inside the footer */
}

.footer-logo {
    margin-top: 20px;
    width: 200px; /* Adjust the size of the logo as needed */
}

.footer-text {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    color: #000000;
}
